<template>
  <section
    class="section section-skew black-gradient-header bujin-black-section"
    v-if="sensei"
  >
    <div class="container-fluid container-lg">
      <div class="container py-5 px-0">
        <div class="row">
          <div class="col-12 d-block d-md-none">
            <img :src="sensei.img_avatar + '?width=600'" class="w-100" />
          </div>
          <div class="col-12 col-md-6">
            <h4 class="p-0 mb-3 border-bottom border-primary">
              <strong v-html="sensei.full_name"></strong>
            </h4>
            <div v-for="(style, index) in sensei.styles" :key="index">
              --> <i class="ni education_hat mr-2"></i>{{ style.name }}
            </div>
            <div class="h6 font-weight-300 text-white">
              <i class="ni location_pin mr-2"></i>{{ sensei.country }}
            </div>
            <div class="small" v-html="sensei.description"></div>
          </div>
          <div class="col-12 col-md-1 d-none d-md-block"></div>
          <div class="col-12 col-md-4 d-none d-md-block">
            <img
              :src="sensei.img_avatar + '?width=600'"
              class="w-100 blend-lighten"
            />
          </div>
          <div class="col-12 col-md-1 d-none d-md-block"></div>
        </div>
      </div>

      <div v-if="sensei.programs.length > 0">
        <h5 class="pt-5 pb-2 text-white"><strong>More videos</strong></h5>
        <div class="row row-grid">
          <div
            class="col-lg-3 col-md-4"
            v-for="(item, i) in sensei.programs"
            v-bind:key="i"
          >
            <router-link :to="'/program/' + item.id" v-lazy-container>
              <img
                class="mw-100 br-5 rounded"
                :data-src="item.img_poster + '?width=320'"
                :data-loading="require('@/assets/img/poster_placeholder.jpg')"
                :data-error="require('@/assets/img/poster_placeholder.jpg')"
              />
            </router-link>
            <p class="small bold lh-140 text-white">{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import findLast from "lodash/findLast";

export default {
  name: "profile",
  metaInfo() {
    const sensei = this.sensei;
    const structuredData = {
      "@context": "http://schema.org",
      "@type": "ProfilePage",
      mainEntity: {
        "@type": "Person",
        name: sensei.full_name,
        image: sensei.img_avatar,
        description: sensei.description,
        nationality: sensei.country,
      },
    };
    return {
      script: [
        {
          type: "application/ld+json",
          json: structuredData,
        },
      ],
      title: sensei.full_name + "Profile Page - Bujin.tv",
    };
  },
  data: function () {
    return {
      senseix: "test",
    };
  },
  created() {
    this.getProfile();
  },
  mounted() {
    if (this.sensei) {
      this.$updateMeta.title({ title: "Bujin.tv - " + this.sensei.full_name });
    }
  },
  computed: {
    sensei: function () {
      return findLast(
        this.$store.state.sensei,
        (item) => item.id == this.$route.params.id
      );
    },
  },
  methods: {
    getProfile: function () {
      if (this.sensei && this.sensei.id == this.$route.params.id) {
        this.loading = false;
      }

      this.axios("/people/" + this.$route.params.id).then((response) => {
        this.loading = false;
        this.$store.commit("setSensei", response.data.data);
      });
    },
  },
};
</script>
